import React from 'react'
import Layout from '../components/Layout'
import { Header } from '../components/Typography'
import { Container } from '../components/TextElements'

const NotFoundPage = () => (
  <Layout>
    <Container>
      <Header>404</Header>
    </Container>
  </Layout>
)

export default NotFoundPage
